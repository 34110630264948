import { Elm } from './elm/Main.elm';
import '@fortawesome/fontawesome-free/css/svg-with-js.min.css';
import Analytics from './analytics';
import * as serviceWorker from './serviceWorker';

import publisher_contact_form from './forms/contact-publisher.form.html';
import update_listing_form from './forms/update-brand-listing.form.html';
import new_listing_form from './forms/new-brand-listing.form.html';
import remove_listing_form from './forms/remove-listing.form.html';
import form_prefix from './forms/prefix.form.html';

let app = Elm.Main.init({
  node: document.getElementById('root'),
  flags: {
    show_welcome: !sessionStorage.getItem('show_welcome'),
    show_thank_you: window.location.hash === '#thankyou',
    window_width: window.innerWidth,
    window_height: window.innerHeight,
  },
});

let analytics = null;

const forms = {
  contact_publisher: form_prefix + publisher_contact_form,
  update_listing: form_prefix + update_listing_form,
  new_listing: form_prefix + new_listing_form,
  remove_listing: form_prefix + remove_listing_form,
};

app.ports.loadFormIntoElement.subscribe(([formName, selector]) => {
  const success = insertHTML(forms[formName], document.querySelector(selector));
  if (!success) {
    setTimeout(() => {
      insertHTML(forms[formName], document.querySelector(selector));
      app.ports.formLoaded.send(true);
    }, 50);
  }
  app.ports.formLoaded.send(true);
});

app.ports.fillInput.subscribe(([selector, value]) => {
  const el = document.querySelector(selector);
  if (el) el.value = value;
});

const scrollListener = evt => {
  const element = evt.target;
  if (element.scrollHeight - element.scrollTop <= element.clientHeight + 50) {
    app.ports.scrolledToBottom.send(true);
  }
};

app.ports.setScrollListener.subscribe(() => {
  setTimeout(() => {
    const el = document.querySelector('#scroll-target');
    if (!el) {
      return;
    }
    el.removeEventListener('scroll', scrollListener);
    el.addEventListener('scroll', scrollListener);
  }, 10);
});

setTimeout(() => {
  sessionStorage.setItem('show_welcome', 'False');
  window.location.hash === '#thankyou' && history.replaceState(null, null, ' ');
}, 0);

app.ports.trackAnalytics.subscribe(({ action, data }) => {
  if (!analytics) {
    analytics = new Analytics(process.env.ELM_APP_GA_ID);
  }
  analytics[action](data);
});

app.ports.setMeta.subscribe(([title, desc]) => {
  document
    .querySelector('meta[name="description"]')
    .setAttribute('content', desc);
  document.querySelector('meta[name="title"]').setAttribute('content', title);
});

const insertHTML = (html, dest) => {
  if (!dest) return false;
  dest.innerHTML = '';
  dest.onclick = e => e.stopPropagation();

  let container = document.createElement('div');
  container.innerHTML = html;

  container.childNodes.forEach(node => {
    if (node.nodeName !== 'SCRIPT') {
      dest.appendChild(node.cloneNode(true));
    }
  });

  let scripts = container.querySelectorAll('script');
  scripts.forEach(s => {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    if (s.hasAttribute('src')) {
      script.src = s.src;
    }
    script.innerHTML = s.innerHTML;
    dest.appendChild(script);
  });

  setTimeout(() => {
    dest.classList.add('repaint');
  }, 100);

  return true;
};

window.onerror = e => {
  if (window.localStorage.getItem('repeatFailure')) {
    console.error(e);
  } else {
    window.localStorage.setItem('repeatFailure', 'true');
    setTimeout(() => window.location.reload(), 100);
  }
};

serviceWorker.register();
