const trackPage = function(url) {
  const ks = window.VSCapture || (() => {});
  ga('set', 'page', url);
  ga('send', 'pageview');
  ks(url);
};

const removeUndefinedValues = object => {
  return Object.keys(object).reduce((acc, key) => {
    if (typeof object[key] !== 'undefined') {
      acc[key] = object[key];
    }

    return acc;
  }, {});
};

const trackEvent = function(eventCategory, eventAction, eventLabel) {
  const eventObject = {
    hitType: 'event',
    eventCategory,
    eventAction,
    eventLabel,
  };

  ga('send', removeUndefinedValues(eventObject));
};

const bootstrapGoogleAnalytics = function(propertyId) {
  (function(i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    (i[r] =
      i[r] ||
      function() {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    a['data-cookieconsent'] = 'statistics';
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    'script',
    'https://www.google-analytics.com/analytics.js',
    'ga',
  );

  ga('create', propertyId, 'auto');
};

export default class Analytics {
  constructor(propertyId) {
    bootstrapGoogleAnalytics(propertyId);
  }

  pageView(url) {
    trackPage(url);
  }

  event({ category, action, label }) {
    trackEvent(category, action, label);
  }
}
